<template>
  <div class="middle-container">
    <!-- <h3>校长信箱管理系统登录</h3> -->
    <div class="container">
      <div class="heard">
        <router-link :to="{name: 'LoginPage'}" active-class="active" class="login"
          >登录</router-link
        >
        <div class="line">|</div>
        <router-link :to="{name: 'SignPage'}" active-class="active" class="login">注册</router-link>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import jwt from 'jwt-decode';
import {adminLogin} from '../../service/getData';
export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      judgeEmail: false,
      password: '',
      loginClick: true
    };
  },
  mounted() {
    console.log(this.$store);
  },
  methods: {
    //登录
    login() {
      adminLogin(this.email, this.password).then(
        (data) => {
          console.log(data);
          if (data.code === 200) {
            let _this = this;
            _this.$message({
              type: 'success',
              message: data.message
            });
            // console.log('登录成功的数据是', data.object.token);
            this.$store.dispatch('token', data.object.token);
            let {role} = jwt(data.object.token);
            setTimeout(function () {
              if (role === '1') {
                _this.$router.push({
                  name: 'ClassifyManagement'
                });
              } else {
                _this.$router.push({
                  name: 'IntroducePage'
                });
              }
            }, 1000);
          } else {
            this.$message({
              type: 'error',
              message: data.message
            });
          }
        },
        (error) => {
          console.log(error);
          this.$message('未知错误');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  border-bottom: 1px solid #23a9f2 !important;
  color: #23a9f2 !important;
}
.middle-container {
  margin: 0 auto;
  margin-top: 110px;

  // margin-top: 130px;
  // padding: 20px;
  width: 100%;
  max-width: 100%;

  // height: 400px;
  border-radius: 5px;

  // transform: translate(-50%, -50%);
  h3 {
    margin-top: 20px;
    font-family: stencil;
    text-align: center;
    color: #23a9f2;
  }
  .container {
    position: relative;
    margin: 0 auto;
    width: 85%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 8px 8px 48px rgb(0 0 0 / 30%);
    .heard {
      display: flex;
      padding: 0 20px;
      width: 100%;
      height: 40px;
      flex-direction: row;
      .login {
        display: block;
        width: 100%;
        height: 100%;
        font-weight: 900;
        border-bottom: 2px solid #ffffff;
        text-align: center;
        text-decoration: none;
        color: black;
        transition: all 0.3s;
        cursor: pointer;
        line-height: 40px;
      }
      .line {
        color: #23a9f2;
        line-height: 40px;
      }
    }
  }
}
#login {
  overflow: auto;
  .login {
    position: relative;
    padding: 0 20px;
    width: 100%;
    height: 350px;
    .num {
      margin-top: 20px;
      height: 20px;
      font-size: 14px;
    }
    .login-ipt {
      // margin-top: 20px;
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border: 0;
      border-bottom: 1px solid #777575;
      outline: none;
    }
    .forget {
      display: block;
      margin-top: 10px;
      text-align: right;
      text-decoration: none;
      color: #23a9f2;
    }
    .login-btn {
      position: absolute;
      top: 200px;
      left: 50%;
      width: 50%;
      height: 40px;
      border: 0;
      text-align: center;
      color: #ffffff;
      background-color: #23a9f2;
      cursor: pointer;
      transform: translateX(-50%);
    }
    .login-btn:active {
      color: #000000;
      background-color: #d4d1d1;
    }
  }
}
</style>
