import { render, staticRenderFns } from "./LoginPageS.vue?vue&type=template&id=1cbdfeeb&scoped=true&"
import script from "./LoginPageS.vue?vue&type=script&lang=js&"
export * from "./LoginPageS.vue?vue&type=script&lang=js&"
import style0 from "./LoginPageS.vue?vue&type=style&index=0&id=1cbdfeeb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cbdfeeb",
  null
  
)

export default component.exports