import axios from 'axios';
import store from '../store';
axios.defaults.baseURL = 'http://mailbox.lovelsj.com';
// axios.defaults.baseURL = 'http://mailbox.lovezsk.top:8081';
// axios.defaults.timeout = 1000;
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers={
    token: store.state.token
  };
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log(response);
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});
export default axios;