import axios from '../axios';

/**
 * 登录
 */
export const adminLogin = (username, password) =>
  axios({
    url: '/mailbox/Admin/adminLogin',
    method: 'post',
    params: {
      username,
      password
    }
  });

/**
 * 注册
 *  chekPassword *
 string
 (query)
 确认密码

 chekPassword - 确认密码
 email *
 string
 (query)
 邮箱(游客账号)

 email - 邮箱(游客账号)
 nickname *
 string
 (query)
 真实姓名

 nickname - 真实姓名
 password *
 string
 (query)
 密码

 password - 密码
 phoneNumber *
 string
 (query)
 电话

  username  - 学号/工号
 username  *
 string
 (query)
 学号/工号
 */
export const sign = (chekPassword, email, nickname, password, phoneNumber, username) =>
  axios({
    url: '/mailbox/User/register',
    method: 'POST',
    params: {
      chekPassword,
      email,
      nickname,
      password,
      phoneNumber,
      username
    }
  });

//修改用户昵称
export const updateNickname = (newNickname) =>
  axios({
    url: '/mailbox/User/updateNickname',
    method: 'PUT',
    params: {
      newNickname
    }
  });

//修改密码
export const userUpdateUserPassword = (newPassword, oldPassword) =>
  axios({
    url: '/mailbox/User/updateUserPassword',
    method: 'PUT',
    params: {
      newPassword,
      oldPassword
    }
  });

//获取用户信息
export const userMessage = () =>
  axios({
    url: '/mailbox/Visitor/updateNickname'
  });

/**
 * 后台问题类别
 */

//后台遍历所有问题类别
export const findAllType = (pageNow = 1, pageSize = 5) =>
  axios({
    url: '/mailbox/Admin/findAllType',
    params: {
      pageNow,
      pageSize
    }
  });

//后台单个删除问题类别
export const deleteTypeById = (typeId) =>
  axios({
    url: '/mailbox/Admin/deleteTypeById',
    method: 'DELETE',
    params: {
      typeId
    }
  });

//后台批量删除问题类别
export const batchDeleteType = (typeId) =>
  axios({
    url: `/mailbox/Admin/batchDeleteType?${typeId}`,
    method: 'DELETE'
  });

//后台添加问题类别
export const addType = (typeName) =>
  axios({
    url: '/mailbox/Admin/addType',
    method: 'POST',
    params: {
      typeName
    }
  });

//后台问题类别模糊查询
export const findTypeByNameLike = (keyWord, pageNow, pageSize) =>
  axios({
    url: '/mailbox/Admin/findTypeByNameLike',
    params: {
      keyWord,
      pageNow,
      pageSize
    }
  });

//后台修改问题类型名称
export const updateType = (typeId, typeName) =>
  axios({
    url: '/mailbox/Admin/updateType',
    method: 'PUT',
    params: {
      typeId,
      typeName
    }
  });

/**
 * 后台信箱
 */

//遍历所有的留言
export const findAllMessages = (page, size) =>
  axios({
    url: '/mailbox/Admin/findAllMessages',
    params: {
      page,
      size
    }
  });

//根据类型或者时间查询留言（类型为空，有时间代表根据时间查询；有类型，时间空，代表类型查询，都有代表根据两者一起查询）
export const findSuchMessages = (endTime, page, size, startTime, type) => {
  console.log(endTime, page, size, startTime, type);
  let obj = {};
  if (endTime === undefined && startTime === undefined) {
    obj = {
      page,
      size,
      type
    };
  } else if (!(endTime === undefined && startTime === undefined) && type === '') {
    obj = {
      endTime,
      page,
      size,
      startTime
    };
  } else {
    obj = {
      endTime,
      page,
      size,
      startTime,
      type
    };
  }
  return axios({
    url: '/mailbox/Admin/findSuchMessages',
    params: obj
  });
};

//查看未查看的留言
export const findUnSee = () =>
  axios({
    url: '/mailbox/Admin/findUnSee'
  });

//查看未查看的留言的数目
export const findUnSeeCounts = () =>
  axios({
    url: '/mailbox/Admin/findUnSeeCounts'
  });

//根据内容查询留言
export const selectByContent = (content, page, size) =>
  axios({
    url: '/mailbox/Admin/selectByContent',
    params: {
      content,
      page,
      size
    }
  });

//根据邮箱查询留言
export const selectByEmail = (email, page, size) =>
  axios({
    url: '/mailbox/Admin/selectByEmail',
    params: {
      email,
      page,
      size
    }
  });

//根据姓名查询留言
export const selectByName = (name, page, size) =>
  axios({
    url: '/mailbox/Admin/selectByName',
    params: {
      name,
      page,
      size
    }
  });

//根据电话查询留言
export const selectByPhone = (phone, page, size) =>
  axios({
    url: '/mailbox/Admin/selectByPhone',
    params: {
      page,
      phone,
      size
    }
  });

//批量删除留言（单个也可以）
export const deleteMessages = (ids) =>
  axios({
    url: '/mailbox/Admin/deleteMessages',
    method: 'DELETE',
    params: {
      ids
    }
  });

//管理员回复留言
export const replyMessage = (messageId, replyContent) =>
  axios({
    url: '/mailbox/Admin/replyMessage',
    method: 'POST',
    params: {
      messageId,
      replyContent
    }
  });

//管理员打开公开信件
export const openMessage = (messageId) =>
  axios({
    url: '/mailbox/Admin/openMessage',
    method: 'PUT',
    params: {
      messageId
    }
  });

//管理员关闭公开信件
export const closeMessage = (messageId) =>
  axios({
    url: '/mailbox/Admin/closeMessage',
    method: 'PUT',
    params: {
      messageId
    }
  });
//管理员查看所有已公开的信件
export const findOpenMessage = () =>
  axios({
    url: '/mailbox/Admin/findOpenMessage'
  });
//管理员查看所有未公开的信件
export const findNotOpenMessage = () =>
  axios({
    url: '/mailbox/Admin/findNotOpenMessage'
  });
/**
 * 后台管理员修改信息
 */

//修改密码
export const updateUserPassword = (newPassword, oldPassword) =>
  axios({
    url: '/mailbox/Admin/updateUserPassword',
    method: 'PUT',
    params: {
      newPassword,
      oldPassword
    }
  });

/**
 * 后台我的回复
 */

//遍历管理员后台回复的信息
export const findAllReply = (pageNow = 1, pageSize = 5) =>
  axios({
    url: '/mailbox/Admin/findAllReply',
    params: {
      pageNow,
      pageSize
    }
  });

//管理员删除回复的留言
export const deleteReply = (replyId) =>
  axios({
    url: '/mailbox/Admin/deleteReply',
    method: 'DELETE',
    params: {
      replyId
    }
  });

//管理员修改已经回复过的留言
export const updateReply = (replyId, content) =>
  axios({
    url: '/mailbox/Admin/updateReply',
    method: 'PUT',
    params: {
      content,
      replyId
    }
  });

//
export const batchDeleteReply = (typeId) =>
  axios({
    url: `/mailbox/Admin/batchDeleteReply?${typeId}`,
    method: 'DELETE'
  });

//留言页打开回复的详细信息
export const findReplyByMessageId = (messageId) =>
  axios({
    url: '/mailbox/Admin/findReplyByMessageId',
    params: {
      messageId
    }
  });

//管理员后台模糊查询回复信息
export const findReplyByNameLike = (keyWord, pageNow, pageSize) =>
  axios({
    url: '/mailbox/Admin/findReplyByNameLike',
    params: {
      keyWord,
      pageNow,
      pageSize
    }
  });

///mailbox/Visitor/Admin/findUnSee
export const findUnSeeState = (page, size, status) => {
  console.log('status', status);
  return axios({
    url: '/mailbox/Admin/findUnSee',
    params: {
      page,
      size,
      status
    }
  });
};

//查询当前所有的留言类型
export const findAllTypes = () =>
  axios({
    url: '/mailbox/Visitor/Reception/findAllTypes'
  });

//前台查看公开的留言
export const findOpenMessages = (page, size) =>
  axios({
    url: '/mailbox/User/Reception/findOpenMessages',
    params: {
      page,
      size
    }
  });
